








import { Component, Vue } from "vue-property-decorator";

@Component
export default class Logout extends Vue {
  created() {
    this.$store.dispatch("auth/logout").finally(() => this.destroyApiToken());
  }

  destroyApiToken() {
    this.$store.commit("auth/destroyApiToken");
    this.$router.replace({ name: "login" });
  }
}
